<template>
  <div id="how-it-works" class="position-relative overflow-hidden">
    <div class="container space-2">
      <div class="w-md-85 w-lg-65 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">HOW IT WORKS</span>
        <h2>Effortlessly Manage Your API Developers</h2>
        <p>With FabirXAPI, you can easily gain access to your developer information and efficiently manage their subscriptions.</p>
      </div>

      <div class="row align-items-center mb-9">
        <div class="col-12 col-md-6 mb-2 mb-md-0">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-1.svg">
          </figure>
          <h3 class="mb-3">Get to Know Your Developers</h3>
          <p>Within the API Portal, API Providers have visibility into the names, email addresses, and last login times of API Developers who have registered or logged in. Additionally, API Providers can access information regarding their API Developers' active subscriptions, including the date of subscription contract creation.</p>
        </div>
        <div class="col-12 col-md-6">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/management/management-feature-2.svg" alt="Get to Know Your Developers">
          </div>
        </div>
      </div>

      <div class="row align-items-center mb-9">
        <div class="col-12 col-md-6 mb-2 mb-md-0 order-md-2">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-2.svg">
          </figure>
          <h3 class="mb-3">Effortless User Invitations and Onboarding</h3>
          <p>API Providers have the capability to invite users, including those without a FabriXAPI account, to the API Portal or invite them to a specific API Plan directly from the Developer List. This empowers API Providers to seamlessly extend invitations and onboard developers to their API offerings, ensuring a seamless user experience for all.</p>
        </div>
        <div class="col-12 col-md-6 order-md-1">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/community/community-feature-3.svg" alt="Effortless User Invitations and Onboarding">
          </div>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-12 col-md-6 mb-2 mb-md-0">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-3.svg">
          </figure>
          <h3 class="mb-3">Manage API Developer's subscription at Ease</h3>
          <p>API Providers have the ability to manage developer subscriptions by either stopping renewals or terminating them. This grants providers enhanced flexibility in managing their subscriptions effectively.</p>
        </div>
        <div class="col-12 col-md-6">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/management/management-feature-3.svg" alt="Manage API User's subscription at Ease">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'bootstrap'
const $ = require('jquery')

export default {
  name: 'HowItWorks',
  mounted() {
    $('#videoModal').on('hidden.bs.modal', function(e) {
      $('#videoModal iframe').attr('src', $('#videoModal iframe').attr('src'))
    })
  }
}
</script>

<style lang="scss" scoped>
.modal-header {
  border-bottom: 0;
}
</style>

<template>
  <div>
    <Hero />
    <HowItWorks />
    <!-- <Showcases /> -->
    <!-- <CTA /> -->
    <MoreFeatures />
    <BnCTA />
    <OahOverview class="space-top-2" />
  </div>
</template>

<script>
import Hero from './components/hero'
import HowItWorks from './components/how-it-works'
// import Showcases from './components/showcases'
// import CTA from './components/cta'
import MoreFeatures from './components/more-features'
import BnCTA from './components/bn-cta'
import OahOverview from '@/components/oah-overview'

export default {
  name: 'UserManagement',
  components: {
    Hero,
    HowItWorks,
    // Showcases,
    // CTA,
    MoreFeatures,
    BnCTA,
    OahOverview
  },
  metaInfo() {
    return {
      title: 'FabriXAPI',
      titleTemplate: 'Developer Management | %s',
      link: [
        { rel: 'canonical', href: 'https://www.fabrixapi.com/developer-management' }
      ],
      meta: [
        { name: 'description', content: 'Leverage FabriXAPI to manage your API users.' },
        { property: 'og:site_name', content: 'FabriXAPI' },
        { property: 'og:title', content: 'Developer Management | FabriXAPI' },
        { property: 'og:description', content: 'Leverage FabriXAPI to manage your API users.' },
        { property: 'og:image', content: 'https://www.fabrixapi.com/assets/img/thumbnails/fabrixapi-thumbnail.jpg' },
        { property: 'og:url', content: 'https://www.fabrixapi.com/developer-management' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
